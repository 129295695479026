<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-3-2banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">数据湖迁移</div>
            <div class="head-explainBox-content1">美国某知名生物制药公司</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">通过信息化建设客户已经具备了较完善的业务及其支撑系统，并积累了海量数据。但是这些数据分散在多个
                独立的系统中，数据异构、分散、无法共享,很难全面的利用和分析。而生产力绩效分析应分别从销售、行为和市场三个方面入手进行相关分析，所以
                此次报表迁移项目旨在打破信息孤岛，搭建好生产力绩效的数据仓库模型，支撑多角度的KPI和纬度分析。</div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
                <div class="purchase-content" style="width:24.8rem">由SQL Server迁移到Amazon Redshift，涉及Tableau Dashboard中28个项目报表，迁移后性能得到显著提升。</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../../assets/image/selected/03-3-2实施方案.png"/>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'数据湖迁移' },
        { name:'description', content:'通过信息化建设客户已经具备了较完善的业务及其支撑系统，并积累了海量数据。但是这些数据分散在多个独立的系统中，数据异构、分散、无法共享,很难全面的利用和分析。而生产力绩效分析应分别从销售、行为和市场三个方面入手进行相关分析，所以此次报表迁移项目旨在打破信息孤岛，搭建好生产力绩效的数据仓库模型，支撑多角度的KPI和纬度分析。' }
        ]
    },
    data() {
        return {
            
        };
    },

    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        }); 
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.1rem;
    height: 40.8rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 17.8rem;
        height: 20rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: justify;
            width: 14.6rem;
            height: 6rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #7A3C8F;
    }
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
